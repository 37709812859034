import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelopeCollection, ResponseEnvelope } from '@kasasa/fbase-components/lib'; 
import { Disclosure } from '@/services/Disclosure';

interface DisclosureFilter {
	includeDisabled?: number | null;
}

interface DisclosureFindfilter {
	expand?: string | null;
}

export default class DisclosureService extends FBaseService {
	
	findAll(clientId: string, params: DisclosureFilter): Promise<AxiosResponse<ResponseEnvelopeCollection<Disclosure>>> {
		return this.apiClient.get(`/api/inmo-disclosure/${clientId}/disclosure`, { params: params });
	}

	find(clientId: string, id: string, params: DisclosureFindfilter): Promise<AxiosResponse<ResponseEnvelope<Disclosure>>> {
		return this.apiClient.get(`/api/inmo-disclosure/${clientId}/disclosure/${id}`, { params: params });
	}

	create(clientId: string, disclosure: Disclosure): Promise<AxiosResponse<number>> {
		return this.apiClient.post(`/api/inmo-disclosure/${clientId}/disclosure`, disclosure);
	}

	update(clientId: string, disclosure: Disclosure): Promise<AxiosResponse<number>> {
		return this.apiClient.put(`/api/inmo-disclosure/${clientId}/disclosure/${disclosure.id}`, disclosure);
	}

	delete(clientId: string | number, id: string | number): Promise<AxiosResponse<ResponseEnvelope<unknown>>> {
		return this.apiClient.delete(`/api/inmo-disclosure/${clientId}/disclosure/${id}`);
	}

	saveFileVersion(clientId: string, id: string, file: File): Promise<boolean> {
		const formData = new FormData();
		formData.append('newFileVersion', file);

		return this.apiClient.post(`/api/inmo-disclosure/${clientId}/disclosure/${id}/file-version`, formData, {
			headers: {'content-type': 'multipart/form-data'}
		});
	}
}

export {
	DisclosureFilter
};








import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import DisclosureListTable from '@/components/DisclosureListTable.vue';
import DisclosureService from '@/services/DisclosureService';
import { Disclosure } from '@/services/Disclosure';
import { getDisclosureTypeName } from '@/services/Disclosure';

@Component({
	components: {
		DisclosureListTable		
	}
})
export default class DisclosuresPage extends Vue {
	disclosureSvc = new DisclosureService(this.$store);
	isLoaded = false;
	disclosures: Disclosure[] = [];
	
	@Watch('$route', { immediate: true, deep: true })
	loadData(): void {
		this.disclosureSvc.findAll(this.$route.params.clientId, {includeDisabled: 1})
			.then(resp => {
				this.disclosures = resp.data.data;
				this.disclosures.forEach(function(disclosure) {
					disclosure.disclosureTypeName = getDisclosureTypeName(disclosure.disclosureTypeId);
				});
				this.isLoaded = true;				
			})
			.catch(() => {
				// no errors besides 401, 403 and 500 to listen for.
			});

	}
}

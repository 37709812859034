import Vue from 'vue';
import Vuex from 'vuex';
import { pendoPlugin } from '@kasasa/fbase-components/lib';

import { AuthStore, NoticeStore } from '@kasasa/fbase-components/lib';
import { Disclosure, disclosureFactory, Query } from '@/services/Disclosure';

Vue.use(Vuex);
export default new Vuex.Store({
	state: {
		query: new Query(),
		disclosure: disclosureFactory()
	},
	mutations: {
		SET_DISCLOSURE(state, disclosure) {
			state.disclosure = disclosure;
		},

		RESET_QUERY(state) {
			state.query = new Query();
		}
	},
	actions: {
		setNewDisclosure(context, fkClient) {
			const disclosure = disclosureFactory();
			disclosure.fkClient = fkClient;
			context.commit('SET_DISCLOSURE', disclosure);
		},
		setDisclosure(context, disclosure: Disclosure) {
			context.commit('SET_DISCLOSURE', disclosure);
		},
		resetDisclosure(context) {
			context.commit('SET_DISCLOSURE', disclosureFactory());
		},

		resetQuery(context) {
			context.commit('RESET_QUERY');
		}
	},
	modules: {
		auth: AuthStore,
		notices: NoticeStore
	},
	plugins: [pendoPlugin]
});

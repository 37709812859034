export enum DisclosureTypeName {
	"General Disclosure" = 1,
	"Overdraft Disclosure",
	"Other"
}

export function getDisclosureTypeName(disclosureTypeId: number): string {
	return DisclosureTypeName[disclosureTypeId] || "";
}

export const DisclosureTypes = [DisclosureTypeName[1], DisclosureTypeName[2], DisclosureTypeName[3]];

export interface DisclosureAttachment {
	id: number | null;
	relativeUrl: string;
	url: string;
	updated: number;
	authorEmail: string;
	authorName: string;
}

export interface Disclosure {
	id: number | null;
	fkClient: number | null;
	enabled: boolean;
	status: string;
	name: string;
	disclosureTypeId: 1 | 2 | 3;
	disclosureTypeName: string;
	updated: number;
	fileName: string;
	relevantToAllProducts: boolean;
	current: DisclosureAttachment;
	versions: DisclosureAttachment[];
	relevantProducts: string[];
	file: File;
	newFileName: string;
	isFileValid: boolean;
	isInvalidRules: string[];
	fileInputRules: string[];
	newFileNameSuffix: string;
	associatedProductsIsNotEmpty: boolean;
}

export enum FilterStatus {
	All = "All",
	Active = "Active",
	Inactive = "Inactive"
}

export class Query {
	clientId: string | null = null;
	useFilter = false;
	search = '';
	sortBy = 'name';
	sortDesc = false;
	selectedTypeName: string[] = [];
	selectedStatus: FilterStatus = FilterStatus.All;
	page = 1;
	perPage = 10;
}

const dis = {
	id: null,
	fkClient: null,
	enabled: false,
	status: '',
	name: '',
	disclosureTypeId: 1,
	disclosureTypeName: '',
	updated: 0,
	fileName: '',
	relevantToAllProducts: false,
	current: {
		id: null,
		relativeUrl: '',
		url: '',
		updated: 0,
		authorEmail: '',
		authorName: '',
	},
	versions: [],
	relevantProducts: [],
	file: new File(["foo"], "foo.txt", { type: "text/plain", }),
	newFileName: "",
	isFileValid: false,
	isInvalidRules: [],
	fileInputRules: [],
	newFileNameSuffix: '.ext',
	associatedProductsIsNotEmpty: false,
} as Disclosure;

export const disclosureFactory = function (): Disclosure {
	return Object.assign({}, dis);
};

export const DisclosureTypesItems = [
	{ text: DisclosureTypeName[1], value: 1 },
	{ text: DisclosureTypeName[2], value: 2 },
	{ text: DisclosureTypeName[3], value: 3 },	
];

export enum cdnHost  {
	dev =  'cdn.dev.firstbranchcms.com',
	uat = 'cdn.uat.firstbranchcms.com',
	prod =  'cdn.firstbranchcms.com',
}

export const cdnHostMap = new Map<string, string>(Object.entries(cdnHost));














































/**
 * KPage was created to act as a reusable component for a "page", and is 
 * currently used by NewVersionPage. Itneeds more refactoring to achieve 
 * the robustness to be used by other pages, and eventually should be moved to 
 * the shared fbase componentslibrary. If progression is made, please update, 
 * or when appropriate, delete this comment.
 * @author James Hester <james.hester@kasasa.com>
**/

import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import KButtonModel from '../components/KButtonModel';

import {
	KCardHeader,
	KCardHeaderBtn,
	KCardFooter,
	KCardFooterBtn,
	KSpinner
} from '@kasasa/fbase-components';

@Component({
	components: {
		KCardHeader,
		KCardHeaderBtn,
		KCardFooter,
		KCardFooterBtn,
		KSpinner
	}
})
export default class KPage extends Vue {

	@Prop() cancelAction !: () => void;

	@Prop() footerButtons !: KButtonModel[];

	@Prop({default: 'unloaded'}) loadStatus!: string;

	@Prop({default: ''}) title!: string;

	@Prop({default: []}) breadcrumbs!: Array<Record<string, unknown>>;
}


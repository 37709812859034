






















































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { RuleDecl } from 'vue/types/options';
import { required } from 'vuelidate/lib/validators';
import KFileInput from '../components/KFileInput.vue';
import { KSelect, KText, KRadioGroup, KCardHeaderBtn, KCheckbox, KMapListManager } from '@kasasa/fbase-components';
import { Alert, NoticeClass } from '@kasasa/fbase-components/lib';
import { Disclosure, DisclosureTypesItems }  from '@/services/Disclosure';

@Component({
	components: {
		KSelect,
		KText,
		KRadioGroup,		
		KCardHeaderBtn,
		KMapListManager,
		KCheckbox,
		KFileInput
	},
}) 

export default class DisclosureForm extends Vue {
	@State('disclosure') disclosure!: Disclosure;
	disclosureTypesItems= DisclosureTypesItems;
	renameFile =  false;
	fileName = '';
	sameAsFileName = this.$route.meta.mode === 'edit'? false: true;
	isSameAsFileNameCheckboxTouched = false;

	sameAsFileNameLabel(): string {
		let label = "New Display Name";
		if ( this.$route.meta.mode === 'edit') {
			label = "Display Name";
		}
		return label;
	}

	created(): void {
		if ( this.$route.meta.mode !== 'edit') {
			this.clear();
		} 
	}

	productMappingIsAffected() : boolean {
		return this.disclosure.relevantToAllProducts
			|| this.disclosure.associatedProductsIsNotEmpty;
	}

	onTypeChange(): void {
		if (this.$v.disclosure.disclosureTypeId) {
			this.$v.disclosure.disclosureTypeId.$touch();
		}
		if (this.disclosure.disclosureTypeId === 3 && this.productMappingIsAffected()) {
			const alert = new Alert('Warning: Changing document type to Other will remove any associations to products.', NoticeClass.INFO);		
			alert.setTimeout(3000);
			this.$store.dispatch('notices/add', alert);
		}
	}

	remameFile(): void {
		this.renameFile = !this.renameFile;
		if(!this.renameFile && !this.disclosure.newFileName){
			this.disclosure.newFileName = this.fileName;
		}
	}

	clear(): void {
		this.disclosure.fileName = '';
		this.disclosure.newFileName = '';
		this.disclosure.name = '';
		this.disclosure.newFileNameSuffix = '.ext';
		this.disclosure.isInvalidRules = [];
	}

	onFileSelected(file: File) : void {
		this.clear();
		this.disclosure.file = file || null;
		const fileExtension = this.extractExtensionFromFileName(this.disclosure.file.name);
		this.fileName = this.extractFileNameFromFile(this.disclosure.file.name);
		this.disclosure.fileName = this.disclosure.file.name;
		this.disclosure.newFileName = this.fileName;
		this.disclosure.name = this.fileName;
		this.disclosure.newFileNameSuffix = `.${fileExtension}`;
		this.disclosure.isFileValid = !this.isInvalid;
	}

	extractFileNameFromFile(fileName: string) : string {
		const name = fileName.split('.');
		name.pop();
		return name.join('.') || '';
	}

	extractExtensionFromFileName(fileName: string) : string {
		return fileName.split('.').pop() || '';
	}

	get isInvalid() : boolean {
		if (!this.disclosure.file) return true;
		const extension = this.extractExtensionFromFileName(this.disclosure.file.name);
		const disclosureExtension = this.extractExtensionFromFileName(this.disclosure.fileName);
		const rules = [
			this.extensionIsIllegal(extension) ? `This file type is not allowed (${extension}).` : '',
			extension !== disclosureExtension ? `Please upload the same file type (${disclosureExtension}).` : '',
			this.disclosure.file !== null && this.disclosure.file.size > 6 * 1000000 ? `File exceeds 6 MB. Please upload a different file or reduce file size.` : ''
		];
		this.disclosure.isInvalidRules = rules.filter(rule => rule !== '');
		return this.disclosure.isInvalidRules.length > 0 ? true : false;
	}

	extensionIsIllegal(extension: string) : boolean {
		return ['exe', 'sh'].some(ext => ext === extension);
	}

	addValidation(): RuleDecl {
		return {
			disclosure: {
				name: { 
					required,
				},
				disclosureTypeId: {
					required,
				},
				enabled: {
					required,
				},
				fileName: {
					required,
				},
				newFileName: {
					required,
				}
			},
		};
	}

	editValidation(): RuleDecl {
		return {
			disclosure: {
				name: { 
					required,
				},
				disclosureTypeId: {
					required,
				},
				enabled: {
					required,
				},
			},
		};
	}

	validations(): RuleDecl {
		if ( this.$route.meta.mode === 'edit') {
			return this.editValidation();
		} else {
			return this.addValidation();
		}		
	}

	get nameErrors(): string {		
		return this.$v.disclosure.name?.$error ? 'Name is required. Please complete this field.' : '';
	}

	get fileErrors(): string {		
		return this.$v.disclosure.fileName?.$error ? 'File is required. Please upload a file.' : '';
	}

	get newFileNameErrors(): string {		
		return this.$v.disclosure.newFileName?.$error ? 'Name is required. Please complete this field.' : '';
	}

	displayNameChanged(): void {
		this.isSameAsFileNameCheckboxTouched = true;
		if (this.$route.meta.mode == 'edit') {
			if (this.sameAsFileName) {
				this.disclosure.name = this.extractFileNameFromFile(this.disclosure.fileName);
			}
			if (this.$v.disclosure.name) {
				this.$v.disclosure.name.$touch();
			}
		} else {
			if (this.sameAsFileName) {
				this.disclosure.name = this.disclosure.newFileName;
			}
			if (this.$v.disclosure.newFileName) {
				this.$v.disclosure.newFileName.$touch();
			}
		}
	}
}

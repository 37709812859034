























































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import { State, Action } from 'vuex-class';
import { Route, RawLocation } from 'vue-router';
import { Validation } from 'vuelidate';
import { RuleDecl } from 'vue/types/options';
import uniq from 'lodash/uniq';
import orderby from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';

import { KCardHeader, KSelect, KRadioGroup, KCheckbox, KText, KCardFooter, KCardFooterBtn, KSpinner, KMapListManager, KSwitch } from '@kasasa/fbase-components';
import { Dialog, Alert, NoticeClass, NoticeResponse } from '@kasasa/fbase-components/lib';
import DisclosureService from '@/services/DisclosureService';
import { Disclosure } from '@/services/Disclosure';
import { ProductService, Product, mapProducts } from '@/services/ProductService';
import DisclosureForm from '@/components/DisclosureForm.vue';

interface Error {
	code: string;
	message: string;
	field: string | null;
	errors: Error[];
}

@Component({
	components: {	
		KCardHeader,
		KSelect,
		KRadioGroup,
		KCheckbox,
		KText,
		KSpinner, 
		KCardFooter, 
		KCardFooterBtn,
		DisclosureForm,
		KSwitch,
		KMapListManager,
	},
	computed: {
	}
})
export default class DisclosureFormPage extends Vue {
	@State('disclosure') disclosure!: Disclosure;
	@Action('setNewDisclosure') setNewDisclosure!: (fkClient:string ) => void ;
	@Action('setDisclosure') setDisclosure!: (disclosure: Disclosure) => void;
	@Action('resetDisclosure') resetDisclosure!: () => void;

	resolve!: (value?: void | PromiseLike<void> | undefined) => void;

	disclosureSvc = new DisclosureService(this.$store);

	productSvc: ProductService = new ProductService(this.$store);
	products: Product[] = [];
	selectedProducts:  Product[] = [];

	loadedStatus='loaded';
	
	get crumbs(): Record<string, unknown>[] {
		const crumbs: Record<string, unknown>[] = [{
			key: '0',
			text: 'Documents',
			disabled: false,
			link: true,
			exact: true,
			to: { name: 'Disclosures', params: { clientId: this.$route.params.clientId } }
		}];

		if (this.$route.meta.mode == 'add') {
			crumbs.push({
				key:'1',
				text: 'Add Disclosure',
				disabled: true
			});

			return crumbs;
		}

		crumbs.push({
			key: '1',
			text: 'Details',
			disabled: false,
			link: true,
			exact: true,
			to: { name: 'ViewDisclosure', params: this.$route.params }
		},
		{
			key: '2',
			text: this.title,
			disabled: true
		});

		return crumbs;
	}

	get title(): string {
		return this.$route.meta.mode == 'add' ? 'Add New Disclosure' : 'Edit';
	}

	kSwitch() : void {
		if (this.disclosure.relevantToAllProducts) {
			this.products = [...this.products, ...this.selectedProducts];
			this.products = orderby(this.products, [p => p.title.toLowerCase()]);
			this.selectedProducts = [];
		}
		if (this.$v.disclosure.relevantToAllProducts) {
			this.$v.disclosure.relevantToAllProducts.$touch();
		}
	}

	validations(): RuleDecl {		
		return {
			disclosure: {
				relevantToAllProducts: {},	
			},
		};
	}

	@Watch('selectedProducts')
	watchSelectedProducts() : void {
		this.disclosure.associatedProductsIsNotEmpty = !! (this.selectedProducts.length > 0);
	}

	@Watch('products')
	watchProducts() : void {
		this.disclosure.associatedProductsIsNotEmpty = !! (this.selectedProducts.length > 0);
	}

	@Watch('$route', { immediate: true, deep: true })
	loadData(): void {
		this.loadedStatus = 'unloaded';
		this.resetDisclosure();
		if (this.$route.meta.mode === 'edit') {
			this.disclosureSvc.find(this.$route.params.clientId, this.$route.params.id, {expand: 'relevantProducts'})
				.then(resp => {
					this.setDisclosure(resp.data.data);
					this.productSvc.findAll(this.$route.params.clientId)
						.then(resp => {
							this.products = resp.data.data;					
							mapProducts(this.products, this.selectedProducts, this.disclosure.relevantProducts);
							this.products = orderby(this.products, [p => p.title.toLowerCase()]);
							this.selectedProducts = orderby(this.selectedProducts, [p => p.title.toLowerCase()]);
							this.loadedStatus = 'loaded';
						}).catch(() => {
							// no errors besides 401, 403 and 500 to listen for.
						});
				})
				.catch(e => {
					// no need to listen for 401, 403 and 500
					if (e.response.status === 404) {
						// throw an Alert
						const notFound = new Alert(`Disclosure "${this.$route.params.id}" is not found. Go back and try again.`, NoticeClass.ERROR);
						this.$store.dispatch('notices/add', notFound);
					}
				});
		} else {
			//todo "add" route	
			this.setNewDisclosure(this.$route.params.clientId);
			this.disclosure.enabled = true;
			this.productSvc.findAll(this.$route.params.clientId)
				.then(resp => {	
					this.products = resp.data.data;							
					mapProducts(this.products, this.selectedProducts, this.disclosure.relevantProducts);
					this.products = orderby(this.products, [p => p.title.toLowerCase()]);
					this.selectedProducts = orderby(this.selectedProducts, [p => p.title.toLowerCase()]);
					this.loadedStatus = 'loaded';
					this.disclosure.relevantToAllProducts = false;
				}).catch(() => {
					// no errors besides 401, 403 and 500 to listen for.
				})
				.catch(e => {
					// no need to listen for 401, 403 and 500
					if (e.response.status === 404) {
						// throw an Alert
						const notFound = new Alert(`Disclosure "${this.$route.params.id}" is not found. Go back and try again.`, NoticeClass.ERROR);
						this.$store.dispatch('notices/add', notFound);
					}
				});
		}
	}

	checkForDirty(): boolean {
		const disclosureForm: Vue = (this.$refs.disco as Vue & { $v: Validation});
		if ( this.$route.meta.mode === 'edit') {
			return this.loadedStatus == 'loaded' &&
				( disclosureForm.$v.$anyDirty 
				|| !isEqual(this.disclosure.relevantProducts.sort(), this.mapSelectedProductsId(this.selectedProducts).sort())
				|| this.$v.$anyDirty 
				);
		} else {
			return this.dataIsDirty;
		}
	}

	get dataIsDirty() : boolean {
		return  this.loadedStatus == 'loaded' && (!!this.disclosure.fileName || !!this.disclosure.name || !!this.disclosure.newFileName);
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	async beforeRouteLeave(to: Route, from: Route, next: Function): Promise<void> {
		if (this.checkForDirty()) {
			const dialog = new Dialog('Unsaved Changes', 'You have unsaved changes on this page that will be lost if you leave now. Are you sure?', 'LEAVE WITHOUT SAVING');
			dialog.setDeclineLabel('STAY ON THIS PAGE')
				.setDismissable(false);

			const res = await this.$store.dispatch('notices/add', dialog);
			switch (res) {
				case NoticeResponse.ACCEPT: 
					next();
					break;
			}
		} else {
			next();
		}
	}

	navigateBackAction(): void {
		// beforeRouteLeave() will detect unsaved changes
		const route = {params: this.$route.params} as RawLocation & { name: string};
		if (this.$route.meta.mode == 'edit') {
			route.name = 'ViewDisclosure';
		} else {
			route.name = 'Disclosures';
		}
		this.$router.push(route);
	}

	saveAction(): Promise<void> {
		return new Promise((resolve) => {
			this.resolve = resolve;
			this.upsertClient();			
		});
	}

	saveCloseAction(): Promise<void> {
		return new Promise((resolve) => {
			this.resolve = resolve;
			this.upsertClient(true);
		});	
	}

	checkForErrors(): boolean {
		let errors = false;
		const disclosureForm: Vue = (this.$refs.disco as Vue & { $v: Validation});

		disclosureForm.$v.$touch();
		errors = disclosureForm.$v.$error;

		if (errors) {
			const broken = new Alert('Unable to save. Please make sure all required fields are completed without errors.', NoticeClass.ERROR);
			this.$store.dispatch('notices/add', broken);
		}

		return errors;
	}

	resetUnsavedChanges(): void {

		const disclosureForm: Vue = (this.$refs.disco as Vue & {$v: Validation});

		if (!disclosureForm) return;

		disclosureForm.$v.$reset();
		this.$v.$reset();
	}

	uploadFile( disclosureId: string, close: boolean ) : void {
		const created = new Alert(`${this.disclosure.name} is successfully created.`, NoticeClass.SUCCESS);
		created.setTimeout(6000);
		this.disclosureSvc.saveFileVersion(this.$route.params.clientId, disclosureId, this.disclosure.file)
			.then(() => {
				this.$store.dispatch('notices/add', created);
				this.resetUnsavedChanges();
				this.resolve();
				if(close) {
					this.$router.push({name: 'Disclosures', params: {clientId: this.$route.params.clientId}});
				} else {
					this.$router.push({ name: 'ViewDisclosure', params: { clientId: this.$route.params.clientId, id: disclosureId } });
				}	
			}).
			catch((err) => {
				//todo make inmo-disclosure API return error data
				this.manageErrors(err.response.data.error.errors);
				this.resolve();
			});
	}

	isSelectedFileValid(): boolean {
		if(!this.disclosure.isFileValid) {
			this.disclosure.fileInputRules = this.disclosure.isInvalidRules;
			return false;
		}
		return true;
	}

	resolveRelevantProducts(): void {

		this.disclosure.relevantProducts = [];

		if (this.disclosure.disclosureTypeId === 3) this.disclosure.relevantToAllProducts = false;

		//clear any current saved data
		if (this.disclosure.relevantToAllProducts || this.disclosure.disclosureTypeId === 3) {
			//move any selected products to available products if needed
			this.selectedProducts.forEach((p) => this.products.push(p));
			this.products = orderby(this.products, [p => p.title.toLowerCase()]);				
			//clear map selection
			this.selectedProducts = [];
		} else {
			//store new selection
			this.disclosure.relevantProducts  = this.mapSelectedProductsId(this.selectedProducts);
		}
	}

	upsertClient(close = false): void {
		if (this.checkForErrors()) {
			this.resolve();
			return;
		}
		const saved = new Alert(`${this.disclosure.name} is successfully saved.`, NoticeClass.SUCCESS);
		saved.setTimeout(6000);

		this.resolveRelevantProducts();

		if (this.$route.meta.mode == 'edit') {

			// THIS IS EDIT SAVE FUNCTIONALITY
			this.disclosureSvc.update(this.$route.params.clientId, this.disclosure)
				.then(() => {
					this.$store.dispatch('notices/add', saved);
					this.resetUnsavedChanges();
					this.resolve();
					if(close) {
						this.$router.push({name: 'Disclosures', params: {clientId: this.$route.params.clientId}});
					}	
				}).
				catch((err) => {
					//todo make inmo-disclosure API return error data
					this.manageErrors(err.response.data.error.errors);
					this.resolve();
				});

		} else {
			// THIS IS ADD NEW SAVE FUNCTIONALITY
			const fileNameWithExt = `${this.disclosure.newFileName}${this.disclosure.newFileNameSuffix}`;
			if (this.disclosure.file.name !== fileNameWithExt) {
				this.disclosure.fileName = fileNameWithExt;
			}
			if (this.isSelectedFileValid()) {
				this.loadedStatus = 'unloaded';
				this.disclosureSvc.create(this.$route.params.clientId, this.disclosure)
					.then(( createDisclosureRes: AxiosResponse ) => {
						// Upload the file
						this.uploadFile(createDisclosureRes.data.data.id, close);
					}).
					catch((err) => {
						//todo make inmo-disclosure API return error data
						this.manageErrors(err.response.data.error.errors);
						this.resolve();
					});
			}
		}	
	}

	manageErrors(errors: Error[]): void {
		const fields: string[] = [];
		
		errors.forEach((e: Error) => {
			e.field && fields.push(e.field);
		});
		
		const broken = new Alert('We found some errors with the following fields: ' + uniq(fields).join(), NoticeClass.ERROR);
		this.$store.dispatch('notices/add', broken);
	}

	mapSelectedProductsId(selection: Product[]): string[] {		
		const mappedIds: string[] = [];
		selection.forEach((product) => {
			mappedIds.push(product.id.toString());					
		});
		return mappedIds;
	}
					
}

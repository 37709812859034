import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';
import find from 'lodash/find';
import pullAllBy from 'lodash/pullAllBy';

class ProductService extends FBaseService {
	findAll(clientId: string): Promise<AxiosResponse<ResponseEnvelopeCollection<Product>>> {
		return this.apiClient.get(`/api/kcms-product/client/${clientId}/product`);
	}
}

interface Product {
	id: number;
	fkClient?: string;
	name: string;
	title: string;
}

export {
	Product,
	ProductService
};

export const mapProducts = (products: Product[], selectedProducts: Product[], relevantProducts: string[]): void => {
	products.forEach( (p) => {
		p.title = p.name;
	});
	relevantProducts.forEach((productId) => {
		const prod = find(products, {'id': Number(productId)});								
		if (prod) {
			pullAllBy(products, [prod], 'id');
			selectedProducts.push(prod);									
		}
	});
};
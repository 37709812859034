











































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

import { KSelect, KReadOnly, KCardHeaderBtn, KMapListManager } from '@kasasa/fbase-components';
import { Alert, NoticeClass } from '@kasasa/fbase-components/lib';
import { Disclosure, cdnHostMap }  from '@/services/Disclosure';
import { Site } from '@/services/SiteService';


interface VersionDisplayRow {
	updated: number;
	author: string;
	current: boolean;
	url: string;
}

@Component({
	components: {
		KSelect,
		KReadOnly,
		KCardHeaderBtn,
		KMapListManager
	},
})
export default class DisclosureDetails extends Vue {
	@Prop({ default: null }) readonly disclosure!: Disclosure;
	@Prop({ default: function () { return []; } }) readonly sites!: Site[];

	selectedDomain = '';

	headers: DataTableHeader[] = [
		{ text: 'Date Last Modified',	value: 'updated',    width: '45%' },
		{ text: 'Modified By', 			value: 'author',    width: '45%'},
		{ text: '',						value: 'current', width: '5%' }, 
		{ text: '',						value: 'link', width: '5%' }, 		
	]; 

	get versions (): VersionDisplayRow[] {
		return this.disclosure.versions.map((v, index) => ({
			updated: v.updated,
			author: v.authorName,
			current: !index,
			url: v.url
		}));
	}


	get domainOptions (): { value: string; text: string }[] {
		const domainOptions = [
			{ "value": '', "text": "No Domain (use in KCMS)" },			
		];
		
		this.sites.forEach(function(site) {
			//search for default domain
			const defaultDomain = site.domains.find(domain => domain.isDefault);
			if(defaultDomain) {
				domainOptions.push({value: defaultDomain.domain + '/_', text:defaultDomain.domain + " (use externally)"});
			}
		});
		const cdn = cdnHostMap.get(this.$store.state.auth.configManager.getEnv());
		if (cdn) domainOptions.push({value: cdn, text: cdn + ' (Kasasa hosted)'});
		return domainOptions;
	}

	pad(number: number) : string {
		return (number > 9 ? number : '0' + number) + '';
	}

	formatDate(dateTime: number) : string {
		const date = new Date(dateTime);
		let month = this.pad(date.getMonth() + 1);
		let day = this.pad(date.getDate());
		let year = date.getFullYear();
		let hour = this.pad(date.getHours() % 12 || 12);
		let minutes = this.pad(date.getMinutes());
		let period = date.getHours() > 12 ? "PM" : "AM";

		return `${month}/${day}/${year} ${hour}:${minutes} ${period}`;
	}

	copyToClipBoard(): void {
		let domain = '/_';
		if (this.selectedDomain) {
			domain =  'https://' + this.selectedDomain;
		}
		this.$copyText(domain + this.disclosure.current.relativeUrl);
		const al = new Alert('URL Copied.', NoticeClass.INFO);		
		al.setTimeout(3000);
		this.$store.dispatch('notices/add', al);
	}

}































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';

import DisclosureDetails from '@/components/DisclosureDetails.vue';
import { AuthGroupManager, Dialog, Alert, NoticeResponse, NoticeClass } from '@kasasa/fbase-components/lib';
import { KCardHeaderBtn, KCardHeader, KCardFooter, KCardFooterBtn, KSpinner, KMapListManager } from '@kasasa/fbase-components';
import DisclosureService from '@/services/DisclosureService';
import { Disclosure, getDisclosureTypeName } from '@/services/Disclosure';
import { SiteService, Site } from '@/services/SiteService';
import { ProductService, Product, mapProducts } from '@/services/ProductService';

import { mapState } from 'vuex';
import { namespace } from 'vuex-class';
import orderby from 'lodash/orderBy';

const auth = namespace('auth');

@Component({
	components: {
		DisclosureDetails,
		KCardHeader,
		KCardHeaderBtn,
		KCardFooter,
		KCardFooterBtn,
		KSpinner,
		KMapListManager
	},
	computed: {
		...mapState(['auth'])
	}
})
export default class AddDisclosurePage extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;
	canWrite = false;
	title = 'Details';
	loadStatus = 'loaded';

	disclosure: Disclosure = {} as Disclosure;
	disclosureSvc = new DisclosureService(this.$store);

	siteSvc: SiteService = new SiteService(this.$store);
	sites: Site[] = [];

	productSvc: ProductService = new ProductService(this.$store);
	products: Product[] = [];
	selectedProducts: Product[] = [];

	navigateToEdit(): void {
		this.$router.push({ name: 'EditDisclosure', params: this.$route.params });
	}

	navigateToNewVersion(): void {
		this.$router.push({ name: 'NewVersion', params: this.$route.params });
	}

	closeAction(): void {
		this.$router.push({name: 'Disclosures', params: {clientId: this.$route.params.clientId}});
	}

	created(): void {
		this.canWrite = this.authManager.canWrite('disclosure');
	}

	@Watch('$route', { immediate: true, deep: true })
	loadData(): void {
		this.loadStatus = 'unloaded';
		this.disclosureSvc.find(this.$route.params.clientId, this.$route.params.id, {expand: 'versions,relevantProducts'})
			.then(resp => {
				this.disclosure = resp.data.data;
				this.disclosure.disclosureTypeName = getDisclosureTypeName(this.disclosure.disclosureTypeId);
				this.disclosure.status =  this.disclosure.enabled ? "Active" : "Inactive";
				this.loadStatus = 'loaded';
				this.productSvc.findAll(this.$route.params.clientId)
					.then(resp => {
						this.products = resp.data.data;
						mapProducts(this.products, this.selectedProducts, this.disclosure.relevantProducts);

						const products = orderby(resp.data.data, [p => p.title.toLowerCase()]);
						this.products = this.disclosure.relevantToAllProducts ? [] : products;
						this.selectedProducts = this.disclosure.relevantToAllProducts ? products : orderby(this.selectedProducts, [p => p.title.toLowerCase()]);
					}).catch(() => {
						// no errors besides 401, 403 and 500 to listen for.
					});

				this.siteSvc.findAll(this.$route.params.clientId)
					.then(resp => {
						this.sites = orderby(resp.data.data, ['isDefaultSite', site => site.name.toLowerCase()], ['desc', 'asc']);						
					})
					.catch(() => {
						// no errors besides 401, 403 and 500 to listen for.
					});						
			})
			.catch((e) => {
				// no need to listen for 401, 403 and 500
				if (e.response.status === 404) {
					// throw an Alert notice?
					const notFound = new Alert(`Disclosure "${this.$route.params.id}" is not found. Go back and try again.`, NoticeClass.ERROR);
					this.$store.dispatch('notices/add', notFound);
				}
			});

		
	}

	get items(): Array<Record<string, unknown>> {
		return [
			{
				key: '0',
				text: 'Documents',
				disabled: false,
				link: true,
				exact: true,
				to: { name: 'Disclosures', params: { clientId: this.$route.params.clientId } },
			},
			{
				key: '1',
				text: this.title,
				disabled: true,
			}
		];
	}

	async deleteDisclosure(): Promise<void> {
		const dialog = new Dialog(
			'Delete this Document?',
			`Are you sure you want to delete "${this.disclosure.name}"? Caution: this can’t be undone.`,
			'DELETE'
		);
		dialog.setDeclineLabel('CANCEL')
			.setDismissable(false);
		const res = await this.$store.dispatch('notices/add', dialog);
		switch (res) {
			case NoticeResponse.ACCEPT:
				this.loadStatus = "unloaded";
				this.disclosureSvc.delete(this.$route.params.clientId, this.$route.params.id).then((deleteRes: AxiosResponse) => {
					if (deleteRes.status === 204) {
						const success = new Alert(`${this.disclosure.name} is deleted.`, NoticeClass.SUCCESS);
						success.setTimeout(6000);
						this.$store.dispatch('notices/add', success);
						this.$router.push({name: 'Disclosures', params: this.$route.params});
					} else {
						this.loadStatus = "loaded";
						this.$store.dispatch('notices/add', new Alert(
							`An error occured while deleting "${this.disclosure.name}"`,
							NoticeClass.ERROR
						));
					}
				});
				break;
		}
	}

}
























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { KText } from '@kasasa/fbase-components';

@Component({
	components: {
		KText
	}
})
export default class KFileInput extends Vue {

	files?: File | null = null;

	@Prop({default: '*'}) accept!: string;

	@Prop({default: 6}) maxSize!: number;

	@Prop({default: () => []})  rules!: {(value: string | number | boolean): boolean | string}[];

	@Prop({default: () => []}) customRules!: {(value: string | number | boolean): boolean | string}[];

	@Prop({default: '' }) errorMessages!: string;

	@Emit('file-selected')
	onFileSelect(): File | null | undefined {
		return this.files;
	}

	get sizeDisplay() : string {
		return this.files ? Math.round(this.files.size/10000)/100 + 'MB /' + this.maxSize + 'MB' : '';
	}
}































































































































































import Vue from 'vue';
import { Action, State } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Disclosure } from '@/services/Disclosure';
import { DisclosureTypes, FilterStatus, Query } from '@/services/Disclosure';
import { KSelect, KSpinner } from '@kasasa/fbase-components';

import KCustomSelect from './KCustomSelect.vue';

const reverseSort = (a: number, b: number): number => (a > b ? -1 : 1);

@Component({
	components: {
		KSpinner,
		KSelect,
		KCustomSelect
	}
})
export default class DisclosureListTable extends Vue {

	@Action('resetQuery') resetQuery!: () => void;
	@State('query') query!: Query;

	@Prop({ default: () => [] }) readonly disclosures!: Disclosure[];
	@Prop({ default: false}) readonly isLoaded!: boolean;

	created(): void {
		if (this.query.clientId !== this.$route.params.clientId) this.resetQuery();
		this.query.clientId = this.$route.params.clientId;
	}

	get filterStatusValues() : FilterStatus[] {
		return [FilterStatus.All, FilterStatus.Active, FilterStatus.Inactive];
	}

	disclosureTypes = DisclosureTypes;

	headers: DataTableHeader[] = [
		{ text: 'Display Name',   	   value: 'name',    width: '350px' },
		{ text: 'File Name',   	  		value: 'fileName',    width: '350px' },
		{ text: 'Type',         	   value: 'disclosureTypeName',    width: '190px'},
		{ text: 'Date Last Modified', value: 'updated', width: '160px' },
		{ text: 'Status', 			   value: 'enabled', width: '100px', sort: reverseSort }, // reverse sort so 1 (Active) is ascending
	];

	get displayDisclosures(): Disclosure[] {

		if(!this.query.useFilter) return this.disclosures;

		return this.disclosures.filter((disclosure: Disclosure) => this.typeFilter(disclosure) && this.statusFilter(disclosure));
	}

	pad(number: number) : string {
		return (number > 9 ? number : '0' + number) + '';
	}

	dateLastModified(updatedDate: number) : string {
		const date = new Date(updatedDate);
		let month = this.pad(date.getMonth() + 1);
		let day = this.pad(date.getDate());
		let year = date.getFullYear();

		return `${month}/${day}/${year}`;
	}

	typeFilter(disclosure: Disclosure): boolean {
		return !this.query.selectedTypeName.length || this.query.selectedTypeName.includes(disclosure.disclosureTypeName);
	}

	statusFilter(disclosure: Disclosure): boolean {
		return this.query.selectedStatus === null
			|| this.query.selectedStatus === FilterStatus.All
			|| disclosure.enabled === (this.query.selectedStatus === FilterStatus.Active);
	}

	customFilter(index: number, search: string, item: Disclosure): boolean | string {
		this.query.page = 1;
		const lSearch = search.toLowerCase();
		return (item.name && item.name.toLowerCase().indexOf(lSearch) !== -1)
			|| (item.fileName && item.fileName.toLowerCase().indexOf(lSearch) !== -1);
	}

	resetFilter(): void {
		this.query.selectedTypeName = [];
		this.query.selectedStatus = FilterStatus.All;
	}

	searchHighlight(value: string|number): string | number  {
		if (!this.query.search.length || value === null) {
			return value;
		}
		return value.toString().replace(
			RegExp(this.query.search, 'ig'),
			(matchText) => '<strong style="color: #000;">' + matchText + '</strong>'
		);
	}

	handleClick(item: Disclosure): void {
		if (item && item.id) {
			this.$router.push({ name: 'ViewDisclosure', params: { clientId: this.$route.params.clientId, id: item.id.toString() } });
		}
	}

}

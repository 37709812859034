





















import Vue from 'vue';
import { mapState } from 'vuex';

import { KPortalHeader, KAppToolbar, KNoticeContainer, KAppToolbarBtn } from '@kasasa/fbase-components';

export default Vue.extend({
	name: 'App',

	components: {
		KPortalHeader, KAppToolbar, KNoticeContainer, KAppToolbarBtn
	},

	methods: {
		navigateToAddNew() {
			this.$router.push({ name: 'AddDisclosure', params: { clientId: this.$route.params.clientId }});
		}
	},

	computed: {
		...mapState(['auth']),
		canWrite() {
			return this.auth.authManager.canWrite('disclosure');
		}
	}
});

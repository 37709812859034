
















import DisclosureService from '@/services/DisclosureService';
import KCard from '../components/KCard.vue';
import KFileInput from '../components/KFileInput.vue';
import KButtonModel from '../components/KButtonModel';
import KPage from '../components/KPage.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Alert, Dialog, NoticeClass } from '@kasasa/fbase-components/lib';
import { Disclosure } from '@/services/Disclosure';
import { Route } from 'vue-router';

@Component({
	components: {
		KCard,
		KFileInput,
		KPage
	}
})
export default class NewVersionPage extends Vue {

	get breadcrumbs(): Array<Record<string, unknown>> {
		return [
			{
				key: 0,
				text: 'Disclosures',
				disabled: false,
				link: true,
				exact: true,
				to: { name: 'Disclosures', params: { clientId: this.$route.params.clientId } },
			},
			{
				key: 1,
				text: 'Details',
				disabled: false,
				link: true,
				exact: true,
				to: {
					name: 'ViewDisclosure',
					params: this.$route.params
				},
			},
			{
				key: 2,
				text: this.title,
				disabled: true,
			}
		];
	}


	disclosure: Disclosure = {} as Disclosure;

	disclosureSvc = new DisclosureService(this.$store);

	file: File | null = null;

	fileInputRules : {(file: File) : boolean | string}[] = [];

	footerButtons: KButtonModel[] = [
		{
			label: "SAVE & CLOSE",
			clickAction: this.onSave
		}
	];

	loadStatus = "unloaded";

	title = "Upload a New Version";


	// eslint-disable-next-line @typescript-eslint/ban-types
	async beforeRouteLeave(to: Route, from: Route, next: Function): Promise<void> {
		this.confirmAbandon().then(confirmed => confirmed && next());
	}


	created(): void {
		this.loadData();
	}


	async confirmAbandon() : Promise<boolean> {
		if (!this.dataIsDirty) return true;
		const dialog = new Dialog(
			'Unsaved Changes',
			'You have unsaved changes on this page that will be lost if you leave now. Are you sure you want to leave without saving?',
			'LEAVE WITHOUT SAVING'
		)
			.setDeclineLabel('STAY ON THIS PAGE')
			.setDismissable(false);

		const leave = (await this.$store.dispatch('notices/add', dialog)) === 'accept';
		if (leave) this.file = null;

		return leave;
	}

	get dataIsDirty() : boolean {
		return !!this.file;
	}
	

	extractExtensionFromFileName(fileName: string) : string {
		return fileName.split('.').pop()?.toLowerCase() || '';
	}


	extensionIsIllegal(extension: string) : boolean {
		return ['exe', 'sh'].some(ext => ext === extension);
	}


	loadData(): void {
		this.loadStatus = 'unloaded';
		this.disclosureSvc.find(this.$route.params.clientId, this.$route.params.id, {expand: 'versions'})
			.then(response => {
				this.disclosure = response.data.data;
			})
			.then(() => this.loadStatus = 'loaded');
	}


	async onCancel() : Promise<void> {
		if (await this.confirmAbandon()) this.$router.push({name: 'ViewDisclosure', params: this.$route.params});
	}


	onFileSelected(file: File) : void {
		this.file = file || null;
	}


	async onSave(): Promise<void> {
		if (this.isInvalid) {
			this.$store.dispatch('notices/add', new Alert(this.isInvalid, NoticeClass.ERROR).setTimeout(6000));	
			return;
		}

		if (!this.file) return;
		return this.disclosureSvc.saveFileVersion(this.$route.params.clientId, this.$route.params.id, this.file)
			.then(() => this.onSuccess())
			.catch(error => this.onFailure(error));
	}


	onFailure(error: string) : void {
		this.$store.dispatch('notices/add', new Alert(error, NoticeClass.ERROR).setTimeout(6000));
	}


	onSuccess() : void {
		if (this.file === null ) return;
		if (this.disclosure.fileName !== this.file.name) {
			this.$store.dispatch('notices/add', new Alert(`The new file has a different name.  It was renamed to preserve any existing links.`, NoticeClass.INFO).setTimeout(6000));
		} else {
			this.$store.dispatch('notices/add', new Alert(`${this.disclosure.name} is successfully updated..`, NoticeClass.SUCCESS).setTimeout(6000));
		}
		this.file = null; // reset so data ain't dirtayyy
		this.$router.push({name: 'ViewDisclosure', params: this.$route.params});
	}


	get isInvalid() : string | false {
		if (!this.file) return `You must select a file.`;
		const extension = this.extractExtensionFromFileName(this.file.name);
		const disclosureExtension = this.extractExtensionFromFileName(this.disclosure.fileName);
		const rules = [
			() => this.extensionIsIllegal(extension) ? `This file type is not allowed (${extension}).` : false,
			() => extension !== disclosureExtension ? `Please upload the same file type (${disclosureExtension}).` : false,
			() => this.file !== null && this.file.size > 6 * 1000000 ? `File exceeds 6 MB. Please upload a different file or reduce file size.` : false
		];
		return rules.map(rule => rule()).find(error => error) || false;
	}
}

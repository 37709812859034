import Vue from 'vue';
import VueRouter from 'vue-router';
import DisclosuresPage from '@/pages/DisclosuresPage.vue';
import DisclosureDetailsPage from '@/pages/DisclosureDetailsPage.vue';
import DisclosureFormPage from '@/pages/DisclosureFormPage.vue';
import NewVersionPage from '@/pages/NewVersionPage.vue';
import HomePage from '@/pages/HomePage.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: HomePage
	},
	{
		path: '/:clientId',
		name: 'Disclosures',
		component: DisclosuresPage,
		meta: {
			addDisclosure: true
		},
	},
	{
		path: '/:clientId/disclosure/add',
		name: 'AddDisclosure',
		component: DisclosureFormPage,
		meta: {
			mode: 'add',
		}
	},
	{
		path: '/:clientId/disclosure/:id',
		name: 'ViewDisclosure',
		component: DisclosureDetailsPage,
	},
	{
		path: '/:clientId/disclosure/:id/edit',
		name: 'EditDisclosure',
		component: DisclosureFormPage,
		meta: {
			mode: 'edit',
		}
	},
	{
		path: '/:clientId/disclosure/:id/add-file-version',
		name: 'NewVersion',
		component: NewVersionPage,
	},
	{
		path: '*',
		redirect: { name: 'Home' }
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
